import React from 'react'
import { Box } from 'reflexbox'
import Container from '../components/Container'
import SEO from '../components/SEO'

const TermsOfUse = props => {
  return (
    <>
      <SEO title="Terms of use" />

      {/* Hero */}
      <Container my={[4, 5]}>
        <Box textAlign={'center'}>
          <h1>Terms of use</h1>
          <p className="subtitle">Last updated: August 22, 2020</p>
        </Box>
      </Container>

      {/* Content */}
      <Container mb={[4, 5]}>
        <Box mx="auto" width={[1, 3 / 5]}>
          <Box mb={[4, 5]}>
            <p>
              These terms apply to trial users and subscribers of IconJar. By
              subscribing or using our app, you accept our terms as described
              below.
            </p>
          </Box>
          <Box mb={[4, 5]}>
            <h2>Modification of agreement</h2>
            <p>
              We may make changes to our terms when needed. We’ll update the
              “last updated” date at the very bottom of this page when we do so.
              The new terms will override the previous ones, but we’ll always
              keep revisions if needed.
            </p>
          </Box>
          <Box mb={[4, 5]}>
            <h2>Our product</h2>
            <p>
              IconJar is a macOS only product. We strive to make IconJar
              compatible with the two last major macOS releases. We can not
              guarantee that IconJar will work on beta versions of macOS. The
              app comes “as is” without any warranty of any kind. In no event
              shall the authors or copyright holders be responsible for damages
              or other liability while using IconJar. We’re very willing to
              help, so don’t hesitate to send us an email if you experience any
              issues.
            </p>
          </Box>
          <Box mb={[4, 5]}>
            <h2>Annual subscription</h2>
            <p>
              IconJar is sold under a subscription licensing model through
              Paddle. These subscriptions are annual and are auto-renewed. We
              send a reminder 14 days before a new payment gets withdrawn from
              your account to give you time to cancel your subscription.
            </p>
            <p>
              We have a flexible cancelation policy, so you’re free to cancel
              your subscription at any time. For details on refunds,{' '}
              <a href="#refunds">click here</a>.
            </p>
          </Box>
          <Box mb={[4, 5]}>
            <h2>Licenses</h2>
            <p>
              IconJar licenses can activate two Macs so you can use the app on
              your personal and work computers. Licenses for IconJar 2 can only
              be reset by Paddle for the time being, so please{' '}
              <a href="mailto:info@geticonjar.com" rel="nofollow">
                reach out to us
              </a>{' '}
              when you ran out of activations.
            </p>
          </Box>
          <Box mb={[4, 5]}>
            <h2 id="refunds">Refunds</h2>
            <p>
              We have a 14 day trial period which allows you to discover if the
              app fits your needs. You’re allowed to request a refund within 14
              days after the initial purchase if necessary. We do not give
              refunds for subscription renewals. We will disable the license
              keys when we process the refund.
            </p>
          </Box>
          <Box mb={[4, 5]}>
            <h2>Discounts and coupons</h2>
            <ul>
              <li>
                <p>
                  Student discounts are automatically calculating based on email
                  address. Please send us an email to{' '}
                  <a href="mailto:info@geticonjar.com" rel="nofollow">
                    info@geticonjar.com
                  </a>{' '}
                  if you don't have a .edu email address
                </p>
              </li>
              <li>
                <p>
                  Non-profits can apply for a discount by contacting us on{' '}
                  <a href="mailto:info@geticonjar.com" rel="nofollow">
                    info@geticonjar.com
                  </a>
                  .
                </p>
              </li>
              <li>
                <p>
                  We do promotions from time to time. These can either be sent
                  through us or via one of our partners. Coupons can only be
                  applied to new orders and can’t be used to get a (small)
                  refund on your order.
                </p>
              </li>
            </ul>
          </Box>
          <Box mb={[4, 5]}>
            <h2>Featured icons</h2>
            <p>
              We accept zero liability for freebies or advertised icons sets. If
              a dispute is to be made between authors of two sets then it is to
              be had directly between those individuals and not through IconJar.
            </p>
          </Box>
          <Box mb={[5, 6]}>
            <h2>Support</h2>
            <p>
              Please take a look at our support section when you have questions.
              We’re happy to answer any questions that aren’t listed there on{' '}
              <a href="https://twitter.com/geticonjar" rel="nofollow">
                Twitter
              </a>
              ,{' '}
              <a href="https://facebook.com/geticonjar" rel="nofollow">
                Facebook
              </a>{' '}
              or via{' '}
              <a href="mailto:info@geticonjar.com" rel="nofollow">
                email
              </a>
              .
            </p>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default TermsOfUse
